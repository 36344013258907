import compact from "lodash/compact";
import { Lexicon } from '@nitidbit/lexicon';

export const arrayFromLexicon = ( lex: Lexicon, key: string, subkey?: string ): string[] => {
  const sub = lex.subset(key);

  if (!sub) { return []; }

  let keys = sub.keys();
  if (subkey) keys = keys.filter(k => k.endsWith(subkey));
  const items = keys.map( (k) => sub.get(k) );
  return compact(items);
}

export const isLexiconEditorUnlocked = () => {
  return document.cookie.split(';').filter(item => (item.trim().indexOf('lexicon=') === 0)).length > 0;
};

// function to record event timestamps for user analytics
export const NAMESPACE = 'log';

export const recordEvent = (eventName: string) => {
  let page = window.location.pathname.slice(1);
  let key = `${NAMESPACE}.${page}.${eventName}`;
  let d = new Date();
  let origin = sessionStorage.getItem(key);
  if (origin) {
    let diff = d.valueOf() - Date.parse(origin);
    key += `+${diff.toString()}`;
  }

  if (!sessionStorage.getItem(key)) {
    sessionStorage.setItem(key, d.toISOString());
  }
};

export const clearEvents = () => {
  const len = sessionStorage.length;
  let keys = [];
  for (let i = 0; i < len; i += 1) {
    let key = sessionStorage.key(i);
    if (key && key.startsWith(`${NAMESPACE}.`)) { keys.push(key); }
  }
  keys.forEach((k) => {
    sessionStorage.removeItem(k);
  });
};