import React from "react";
import kebabCase from "lodash/kebabCase";
import { Link } from "react-router-dom";
import { routes } from '../../PageRoutes';
import './SecretDevPage.scss';

const SecretDevPage: React.FC = () => {
  return (
    <div className="secret-dev-page">
      {
        Object.keys(routes)
          .map(route => kebabCase(route))
          .map(formattedRoute => (
            <Link to={formattedRoute} key={formattedRoute}>
              {formattedRoute}
            </Link>
          ))
      }
    </div>
  );
};

export default SecretDevPage;
