import camelCase from "lodash/camelCase";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import Rainbow from "../rainbow/Rainbow";
import { routes } from "../../PageRoutes";
import { recordEvent } from "../../utils";
import "./Template.scss";
import Note from "../hiv_note/Note";

interface TemplateInterface {
  page?: string,
  ariaHidden? : boolean,
  isShowingNotesButton?: boolean
  headerImage?: string,
  isHidingNavs?: boolean,
  headerImgPosition?: string
}

interface MaybeFooterInterface {
  back?: string,
  next?: string,
  isShowingNotesButton: boolean
}

const Template: React.FC<TemplateInterface> = ({ 
  children,
  page,
  ariaHidden = false,
  headerImage = null,
  isShowingNotesButton = false,
  isHidingNavs = false,
  headerImgPosition = 'center'
}) => {
  const location = useLocation();
  const key = camelCase(location.pathname);
  const currentPage = routes[key];
  const back = (currentPage && currentPage.back) || "";
  const next = (currentPage && currentPage.next) || "";
  const isShowingNavs = currentPage && !isHidingNavs;

  return (
    <>
      <Rainbow />
      { headerImage && <img src={headerImage} className={`header-image ${headerImgPosition}`} alt="" /> }
      <main className={page} aria-hidden={ariaHidden} >
        { children }
        { isShowingNavs && <MaybeFooter next={next} back={back} isShowingNotesButton={isShowingNotesButton} /> }
      </main>
    </>
  );
};

export const MaybeFooter: React.FC<MaybeFooterInterface> = ({ back, next, isShowingNotesButton }) => (
  back || next 
    ? (
        <footer>
          { back ? <Link to={back} onClick={(e) => recordEvent("BACK")}>« back</Link> : <div/> }
          { isShowingNotesButton && <Note /> }
          { next ? <Link to={next} onClick={(e) => recordEvent("CONTINUE")}>continue »</Link> : <div/> }
        </footer>
    ) : null
);

export default Template;
