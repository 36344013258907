import { action, ActionType  } from "typesafe-actions";

export enum userIdActionTypeEnums {
  updateUserId = "UPDATE STUDY ID",
}

const userIdActions = {
  updateUserId: (id: string) => action(userIdActionTypeEnums.updateUserId, id),
};

export type userIdActionType = ActionType<typeof userIdActions>

export default userIdActions;
