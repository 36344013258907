import React, { useState, useLayoutEffect } from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { arrayFromLexicon } from "../../utils";
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import texts from './HivStrings.json';
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import DialogWithOverlay from "../../components/ncl/NclDialog/DialogWithOverlay";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import Markdown from "../../components/ncl/Markdown";
import ImageKanban from "../../components/hiv_image_kanban/ImageKanban";
import "./HIV.scss";

import usa_hiv from './usa_hiv.jpg';
import hiv_women_mobile from './hiv_in_women_mobile.jpg';
import hiv_women from './hiv_in_women.jpg';
import hiv_in_jackson from './hiv_in_jackson.jpg';
import hiv_racial_breakdown from './hiv_racial_breakdown.jpg';

interface HivInterface { 
  lexicon: Lexicon
}

const HIV: React.FC<HivInterface> = ({ lexicon }) => {
  const [isJacksonvilleDialogOn, setIsJacksonvilleDialogOn] = useState<boolean>(false);
  const [isWomenDialogOn, setIsWomenDialogOn] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const JacksonvilleDialogToggle = (): void => {
    if (isJacksonvilleDialogOn === true) setIsJacksonvilleDialogOn(false);
    else setIsJacksonvilleDialogOn(true);
  }

  const WomenDialogToggle = (): void => {
    if (isWomenDialogOn === true) setIsWomenDialogOn(false);
    else setIsWomenDialogOn(true);
  }

  const jacksonDialogList = arrayFromLexicon(lexicon, 'jackson.list');
  const womenDialogList = arrayFromLexicon(lexicon, 'women.list');

  const checkWindowWidth = (): void => { setIsMobile(window.innerWidth < 680) }

  useLayoutEffect(() => {
    window.addEventListener('resize', checkWindowWidth);

    return () => { window.removeEventListener('resize', checkWindowWidth) }
  }, [])

  return (
    <>
      <Template
        page="hiv"
        ariaHidden={isJacksonvilleDialogOn || isWomenDialogOn}
      >
        <PageContent
          title={lexicon.get('title')}
          body={lexicon.get('body_1')}
        />
        <ImageKanban
          imageName="HIV in USA"
          headerText={lexicon.get('jackson.image_header')}
          appendixMarkdown={lexicon.get('jackson.appendix')}
          imageSrc={usa_hiv}
        />
        <button
          className="dialog-box PrEP"
          onClick={JacksonvilleDialogToggle}
        >
          {lexicon.get('jackson.button')}
        </button>
        <Markdown text={lexicon.get('body_2')} />
        <ImageKanban
          imageName="HIV in Women"
          headerText={lexicon.get('women.image_header')}
          appendixMarkdown={lexicon.get('women.appendix')}
          imageSrc={isMobile ? hiv_women_mobile : hiv_women}
        />
        <button
          className="dialog-box PrEP"
          onClick={WomenDialogToggle}
        >
          {lexicon.get('women.button')}
        </button>
      </Template>
      {
        isJacksonvilleDialogOn && (
          <DialogWithOverlay
            clickHandler={JacksonvilleDialogToggle}
            isModalOn={isJacksonvilleDialogOn}
            extraClass="jackson-dialog"
          >
            <h1
              className="dialog-box PrEP"
              tabIndex={-1}
              dangerouslySetInnerHTML={{ __html: (lexicon.get('jackson.title') as string) }}
            />
            <div className="dialog-body PrEP">
              <BulletList items={jacksonDialogList} />
              <ImageKanban
                imageName="HIV in Jackson"
                headerText={lexicon.get('jackson.modal.image_header')}
                appendixMarkdown={lexicon.get('jackson.modal.appendix')}
                imageSrc={hiv_in_jackson}
              />
            </div>
          </DialogWithOverlay>
        )
      }
      {
        isWomenDialogOn && (
          <DialogWithOverlay
            clickHandler={WomenDialogToggle}
            isModalOn={isWomenDialogOn}
            extraClass="women-dialog"
          >
            <h1
              className="dialog-box PrEP"
              tabIndex={-1}
              dangerouslySetInnerHTML={{ __html: (lexicon.get('women.title') as string) }}
            />
            <div className="dialog-body PrEP">
              <BulletList items={womenDialogList} />
              <ImageKanban
                imageName="HIV racial breakdown"
                headerText={lexicon.get('women.modal.image_header')}
                appendixMarkdown={lexicon.get('women.modal.appendix')}
                imageSrc={hiv_racial_breakdown}
              />
            </div>
          </DialogWithOverlay>
        )
      }
    </>
  );
};

const HivLexicon = new Lexicon(texts, 'en', 'client/src/pages/HIV/HivStrings.json');

const EditableHiv = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={HivLexicon}
    component={HIV}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableHiv;
