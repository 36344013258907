import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import PageRoutes from "./PageRoutes";
import store from "./store";

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <PageRoutes />
      </Router>
    </Provider>
  );
};

export default App;
