import React from 'react';
import Markdown from '../Markdown';
import './BulletList.scss';
import { BulletListInterface } from './util';

const BulletList: React.FC<BulletListInterface> = ({ items }) => (
  <ul className="bullet-list">
    { 
      items.map((item: string) => (
        <li key={item}>
          <Markdown text={item} />
        </li>
      ))
     }
  </ul>
);

export default BulletList;
