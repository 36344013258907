import React from "react";
import { connect } from 'react-redux';
import { Dispatch } from "redux";
import { rootReducerState } from '../../reducers';
import { unlockProviderActionType, unlockProviderActionTypeEnums } from '../../actions/unlockProviderActions';
import { NclNav,  NavItem } from "../ncl/NclNav/NclNav";
import { recordEvent } from "../../utils";
import arrow from "./main_nav_arrow_white.svg";
import check from "./main_nav_check_white.svg";
import "./Nav.scss";

interface NavInterface {
  items: NavItem[],
  visitMenuLink: (link: string) => object,
  visitedMenuLinks: { [index: string]: boolean }
}

const Nav: React.FC<NavInterface> = ({ items, visitMenuLink, visitedMenuLinks }) => {
  const itemsWithVisited = items.map(item => ({
    ...item,
    visited: visitedMenuLinks[item.link],
    clickHandler: () => { recordEvent(`VISIT-${item.link.slice(1)}`); visitMenuLink(item.link); }
  }))

  return (
    <NclNav itemComponent={NavItemComponent} items={itemsWithVisited} />
  )
};

const NavItemComponent: React.FC<NavItem> = ({ label, visited }) => (
  <li className={`nav-item ${visited ? "visited" : ""}`}>
    <span className="nav-label">{label}</span>
    <img src={visited ? check : arrow} alt={visited ? "visited" : ""} />
  </li>
);

const mapStateToProps = ({ visitedMenuLinks  }: rootReducerState) => ({ visitedMenuLinks  });
const mapDispatchToProps = (dispatch: Dispatch<unlockProviderActionType>) => ({
  visitMenuLink: (link: string) => dispatch({ type: unlockProviderActionTypeEnums.visitMenuLink, payload: link }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
