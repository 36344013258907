import React from "react";

import condomsImage from '../../shared_images/colored_icons/condoms-OFF.svg';
import hivTestingImage from '../../shared_images/colored_icons/hivTesting-OFF.svg';
import treatmentImage from '../../shared_images/colored_icons/treatment-OFF.svg';
import PrEPImage from '../../shared_images/colored_icons/PrEP-OFF.svg';
import PEPImage from '../../shared_images/colored_icons/PEP-OFF.svg';
import arrowImage from '../hiv_nav/main_nav_arrow_white.svg';

interface TabInterface { itemKey: string };

const iconImages: { [index: string]: string } = {
  PrEP: PrEPImage,
  PEP: PEPImage,
  condoms: condomsImage,
  hivTesting: hivTestingImage,
  treatment: treatmentImage,
}

export const hivMethodNames: { [index: string]: string } = {
  PrEP: 'PrEP',
  PEP: 'PEP',
  condoms: 'Condoms',
  hivTesting: 'HIV Testing',
  treatment: 'Treament as Prevention'
};

export const TabletTab: React.FC<TabInterface> = ({ itemKey }) => <img className="tab-icon" src={iconImages[itemKey]} alt={itemKey} />;

export const MobileTab: React.FC<TabInterface> = ({ itemKey }) => (
  <>
    <img className="tab-icon" src={iconImages[itemKey]} alt={hivMethodNames[itemKey]} />
    <p>{hivMethodNames[itemKey]}</p>
    <img className="down-arrow" src={arrowImage} alt="" />
  </>
)