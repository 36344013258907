import React from "react";

const removeLexiconCookie = () => { 
  sessionStorage.removeItem('lexiconServerToken');
 }

const LogoutButton: React.FC = () => (
  <div 
    onClick={removeLexiconCookie}
    className="edit-wrapper-button"
  >
    <a href="/">Logout</a>
  </div>
);

export default LogoutButton;