import { action, ActionType  } from "typesafe-actions";

// use typescript enum rather than action constants
export enum noteActionTypeEnums {
  updateNote = "UPDATE NOTE",
}

const noteActions = {
  updateNote: (note: string) => action(noteActionTypeEnums.updateNote, note),
};

export type noteActionType = ActionType<typeof noteActions>

export default noteActions;
