import React from 'react';
import './DialogWithOverlay.scss';
import Modal from 'react-modal';

interface CloseButtonInterface { 
  clickHandler: () => void,
}

// Modal.setAppElement('#root');

export const CloseButton: React.FC<CloseButtonInterface> = ({ clickHandler }) => (
  <button
    className="close-x"
    onClick={clickHandler}
    type="button"
  />
);

interface DialogWithOverlayInterface {
  clickHandler: () => void,
  isModalOn: boolean,
  extraClass?: string,
}

const DialogWithOverlay: React.FC<DialogWithOverlayInterface> = ({ clickHandler, isModalOn, children, extraClass = '' }) => (
  isModalOn ? (<Modal
    overlayClassName="overlay"
    className={`dialog ${extraClass}`}
    ariaHideApp={false}
    onRequestClose={clickHandler}
    isOpen={isModalOn}
    // parentSelector={() => (document.querySelector('#root') as HTMLElement)}
    shouldCloseOnOverlayClick
  >
    { children }
    <CloseButton clickHandler={clickHandler} />
  </Modal>) : null
);


export default DialogWithOverlay;
