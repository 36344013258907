import React from 'react';
import UcsfImage from './ucsf.svg';
import './UcsfFooter.scss';

type UcsfFooterType = { userId: string }

const UcsfFooter: React.FC<UcsfFooterType> = ({ userId }) => (
  <footer className="ucsf-footer">
    <img className="ucsf-image" src={UcsfImage} alt="University of California, San Francisco" />
    {
      userId && <div className="user-id">ID: {userId}</div>
    }
  </footer>
);

export default UcsfFooter;
