import React, { useState } from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { arrayFromLexicon, recordEvent } from "../../utils";
import texts from "./PreventStrings.json";
import './Prevent.scss';
import Template from "../../components/hiv_template/Template";
import NclTabs from "../../components/ncl/NclTabs/Tabs";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import { TabletTab, MobileTab } from '../../components/hiv_tab/Tab';
import PageContent from "../../components/hiv_page_content/PageContent";
import { hivMethodNames } from '../../components/hiv_tab/Tab';
import ImageBanner, { ColorsType } from "../../components/hiv_image_banner/ImageBanner";
const PreventPageImages = require.context('./images', true)

type imageBannerColorConfig = { [index: string]: ColorsType }
interface PreventInterface { lexicon: Lexicon }
interface TabInterface {
  itemKey: string,
  items: string[],
  bannerTexts: string[],
  bannerColors: ColorsType,
  bannerImageSrc: any
};

const imageBannerColorConfig: imageBannerColorConfig = {
  'PrEP': ['blue', 'orange'],
  'PEP': ['blue', 'green'],
  'condoms': ['blue', 'orange'],
  'hivTesting': ['blue', 'orange'],
  'treatment': ['blue', 'orange'],
}

const hivMethods = ['PrEP', 'PEP', 'condoms', 'hivTesting', 'treatment'];

const ContentComponent: React.FC<TabInterface> = ({ itemKey, items, bannerTexts, bannerColors, bannerImageSrc }) => (
  <>
    <div className={`selected-content ${itemKey}`}>
      <BulletList items={items} />
      {/* <img src={PreventPageImages(`./${itemKey}-illustration.svg`)} alt={itemKey} /> */}
      <ImageBanner
        text1={bannerTexts[0]}
        text2={bannerTexts[1]}
        colors={bannerColors}
        imageSrc={bannerImageSrc}
        method={itemKey}
      /> 
    </div>
  </>
);

const Prevent: React.FC<PreventInterface> = ({ lexicon }) => {
  const [selectedItem, setselectedItem] = useState<string | null>(null);

  const iconToggle = (icon: string): void => {
    recordEvent(`TAB-${icon}`);
    if (selectedItem === icon) setselectedItem(null);
    else setselectedItem(icon);
  }

  const titleBarText = selectedItem ? hivMethodNames[selectedItem] : lexicon.get('select_one');
  
  return (
    <Template page="prevent" isShowingNotesButton>
      <PageContent
        title={lexicon.get('title')}
        body={lexicon.get('body')}
      />
      <NclTabs
        itemKeys={hivMethods}
        TabletTabComponent={TabletTab}
        MobileTabComponent={MobileTab}
        ContentComponent={({ itemKey }) => (
            <ContentComponent 
              itemKey={itemKey}
              items={arrayFromLexicon(lexicon, `${itemKey}.bullets`)}
              bannerTexts={[lexicon.get(`${itemKey}.banner_text_1`), lexicon.get(`${itemKey}.banner_text_2`)]}
              bannerColors={imageBannerColorConfig[itemKey]}
              bannerImageSrc={PreventPageImages(`./${itemKey}-illustration.svg`)}
            />
        )}
        selectedItem={selectedItem}
        clickHandler={iconToggle}
        isShowingTitleBar
        titleBarText={titleBarText}
      />
    </Template>
  );
};

const PreventLexicon = new Lexicon(texts, 'en', 'client/src/pages/prevent/PreventStrings.json');

const EditablePrevent = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={PreventLexicon}
    component={Prevent}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditablePrevent;
