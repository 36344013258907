import kebabCase from "lodash/kebabCase";
import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Route, Switch } from "react-router-dom";
import { Lexicon } from '@nitidbit/lexicon';
import { userIdActionType, userIdActionTypeEnums } from './actions/userIdActions';
import GetIt from "./pages/getIt/GetIt";
import HIV from "./pages/HIV/HIV";
import Options from "./pages/options/Options";
import Prevent from "./pages/prevent/Prevent";
import SideEffects from "./pages/sideEffects/SideEffects";
import Pregnancy from './pages/pregnancy/Pregnancy';
import Start from "./pages/start/Start";
import Welcome from "./pages/welcome/Welcome";
import Why from "./pages/why/Why";
import Menu from "./pages/menu/Menu";
import UseIt from './pages/useIt/UseIt';
import STD from './pages/std/STD';
import Protect from './pages/protect/Protect';
import TalkToProvider from './pages/talkToProvider/TalkToProvider';
import Outro from './pages/outro/Outro';
import SecretDevPage from './pages/SecretDevPage/SecretDevPage';

interface RouteInterface { page: React.FC<{lexicon?: Lexicon }>; back?: string; next?: string; }

interface PageRoutesInterface {
  updateUserId: (userId: string) => object
}

type getNewUserIdType = () => void;

export const routes: {[s: string]: RouteInterface} = {
  getIt: {
    next: "menu",
    back: "menu",
    page: GetIt,
  },
  hiv: {
    next: "options",
    page: HIV,
  },
  menu: {
    page: Menu,
  },
  options: {
    next: "menu",
    page: Options,
  },
  prevent: {
    back: "menu",
    next: "std",
    page: Prevent,
  },
  sideEffects: {
    back: "menu",
    next: 'pregnancy',
    page: SideEffects,
  },
  pregnancy: {
    back: 'side-effects',
    next: 'menu',
    page: Pregnancy
  },
  start: {
    next: "why",
    page: Start,
  },
  useIt: {
    back: "menu",
    next: 'protect',
    page: UseIt,
  },
  welcome: {
    page: Welcome,
  },
  why: {
    next: "hiv",
    page: Why,
  },
  std: {
    page: STD,
    next: 'menu',
    back: 'prevent'
  },
  protect: {
    page: Protect,
    next: 'menu',
    back: 'use-it'
  },
  talkToProvider: {
    page: TalkToProvider,
    next: 'outro',
    back: 'menu'
  },
  // surveyExperience: {
  //   page: SurveyExperience,
  //   next: 'survey-helpfulness',
  //   back: 'talk-to-provider'
  // },
  // surveyHelpfulness: {
  //   page: SurveyHelpfulness,
  //   next: 'survey-recommend',
  //   back: 'survey-experience'
  // },
  // surveyRecommend: {
  //   page: SurveyRecommend,
  //   next: 'survey-revisit',
  //   back: 'survey-helpfulness'
  // },
  // surveyRevisit: {
  //   page: SurveyRevisit,
  //   next: 'outro',
  //   back: 'survey-recommend'
  // },
  outro: {
    page: Outro 
  },
  secretDevPage: {
    page: SecretDevPage
  },
  secretDeveloperPage: {
    page: SecretDevPage
  },
};


const PageRoutes: React.FC<PageRoutesInterface> = ({ updateUserId }) => {
  const getNewUserId: getNewUserIdType = () => {
    fetch('/api/userId', { method: 'POST' })
      .then(res => res.json())
      .then(json => {
        updateUserId(JSON.stringify(json.user_id));
        sessionStorage.setItem('userId', JSON.stringify(json.user_id));
      })
      .catch(err => { console.error(err) })
  }

  useEffect(() => {
    getNewUserId();
  })

  return (
    <Switch>
        <Route exact path="/">
          <Welcome />
        </Route>
        {
          Object.keys(routes).map((key: string) => <Route key={key} path={`/${kebabCase(key)}`} component={routes[key].page} />)
        }
    </Switch>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<userIdActionType>) => ({
  updateUserId: (id: string) => dispatch({ type: userIdActionTypeEnums.updateUserId, payload: id }),
});

export default connect(null, mapDispatchToProps)(PageRoutes);
