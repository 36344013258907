import { StateType } from "typesafe-actions";
import { combineReducers } from "redux";
import notes from "./noteReducer";
import survey from "./surveyReducer";
import userId from "./userIdReducer";
import visitedMenuLinks from "./unlockProviderReducer";

const rootReducer = combineReducers({
  notes,
  survey,
  userId,
  visitedMenuLinks 
});

export type rootReducerState = StateType<typeof rootReducer>;

export default rootReducer;