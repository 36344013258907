import React, { useState } from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import texts from "./StartStrings.json";
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import DialogWithOverlay from "../../components/ncl/NclDialog/DialogWithOverlay";
import Markdown from "../../components/ncl/Markdown";
import StartHeaderImage from './start-header-image.jpg';
import './Start.scss';

interface StartInterface { lexicon: Lexicon };

const Start: React.FC<StartInterface> = ({ lexicon }) => {
  const [isDialogOn, setIsDialogOn] = useState<boolean>(true);

  return (
    <>
      <Template
        page="start"
        ariaHidden={isDialogOn}
        headerImage={StartHeaderImage}
        headerImgPosition="center-left"
      >
        <PageContent
          title={lexicon.get('title')}
          body={lexicon.get('body')}
        />
      </Template>

      {
        isDialogOn && (
          <DialogWithOverlay
            clickHandler={() => { setIsDialogOn(false) }}
            isModalOn={isDialogOn}
            extraClass="start-dialog"
          >
            <div className="dialog-body">
              <Markdown text={lexicon.get('dialog') || ''} />
            </div>
          </DialogWithOverlay>
        )
      }
    </>
  )
};

const StartLexicon = new Lexicon(texts, 'en', 'client/src/pages/start/StartStrings.json');

const EditableStart = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={StartLexicon}
    component={Start}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableStart;
