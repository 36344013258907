import { userIdActionType, userIdActionTypeEnums } from '../actions/userIdActions';

const userIdReducer = (state = '', action: userIdActionType) => {
  switch (action.type){
    case userIdActionTypeEnums.updateUserId: {
      return action.payload
    }

    default:
      return state;
  }
};

export default userIdReducer;
