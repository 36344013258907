import React from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { arrayFromLexicon } from "../../utils";
import Template from "../../components/hiv_template/Template";
import texts from './SideEffectsStrings.json';
import PageContent from "../../components/hiv_page_content/PageContent";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import './SideEffects.scss';

import SideEffectsHeaderImage from './side-effects-header-image.jpg';
import PrEPimage from '../../shared_images/colored_icons/PrEP-OFF.svg';
import PEPimage from '../../shared_images/colored_icons/PEP-OFF.svg';

const methods = ['PrEP', 'PEP'];
const bulletImages = [PrEPimage, PEPimage];

interface SideEffectsInterface { lexicon: Lexicon }

const SideEffects: React.FC<SideEffectsInterface> = ({ lexicon }) => {
  const bulletLists = [
    arrayFromLexicon(lexicon, 'PrEP'),
    arrayFromLexicon(lexicon, 'PEP'),
  ];
  
  return (
    <Template
      page="side-effects"
      headerImage={SideEffectsHeaderImage}
      isShowingNotesButton
    >
      <PageContent
        title={lexicon.get('title')}
        body={lexicon.get('body')}
      />
      <ul className="icon-bullet-list">
        { 
          methods.map((item: string, i: number) => (
            <li key={item}>
              { bulletImages[i] && <img src={bulletImages[i]} alt="" aria-hidden /> }
              <div className={`icon-bullet-grouping ${item}`}>
                <h2>{item}</h2>
                <BulletList
                  items={bulletLists[i]}
                />
              </div>
            </li>
          ))
        }
      </ul>
    </Template>
  );
};

const SideEffectsLexicon = new Lexicon(texts, 'en', 'client/src/pages/sideEffects/SideEffectsStrings.json');

const EditableSideEffects = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={SideEffectsLexicon}
    component={SideEffects}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableSideEffects;
