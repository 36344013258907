import React from "react";
import { connect } from 'react-redux';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { rootReducerState } from '../../reducers';
import Nav from "../../components/hiv_nav/Nav";
import Template from "../../components/hiv_template/Template";
import "./Menu.scss";
import texts from "./MenuStrings.json";
import Note from "../../components/hiv_note/Note";

export enum linkKeys {
  prevent = '/prevent',
  useIt = '/use-it',
  sideEffects = '/side-effects',
  getIt = '/get-it',
  talkToProvider = '/talk-to-provider'
}

interface MenuInterface { 
  isShowingLastLink: boolean,
  lexicon: Lexicon,
}

const Menu: React.FC<MenuInterface> = ({ lexicon, isShowingLastLink }) => {
  let menuItems = [
    {link: linkKeys.prevent, label: lexicon.get('prevent')},
    {link: linkKeys.useIt, label: lexicon.get('useIt')},
    {link: linkKeys.sideEffects, label: lexicon.get('sideEffects')},
    {link: linkKeys.getIt, label: lexicon.get('getIt')},
  ];

  if (isShowingLastLink) menuItems = [ ...menuItems, {link: linkKeys.talkToProvider, label: texts.en.talkToProvider}]

  return (
    <Template page="menu">
      <h1>{lexicon.get('title')}</h1>
      <p>{lexicon.get('body')}</p>
      <Nav items={menuItems}/>
      <footer>
        <div className="space-holder" />
        <Note />
        <div className="space-holder" />
      </footer>
    </Template>
  );
};

const mapStateToProps = ({ visitedMenuLinks }: rootReducerState) => {
  return ({ 
    isShowingLastLink: Object.values(visitedMenuLinks).every((bool: boolean) => bool)
  })
};

const connectedMenu = connect(mapStateToProps)(Menu);

const MenuLexicon = new Lexicon(texts, 'en', 'client/src/pages/menu/MenuStrings.json');

const EditableMenu = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={MenuLexicon}
    component={connectedMenu}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableMenu;
