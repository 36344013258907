import React from "react";
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import texts from './OutroStrings.json';
import PageContent from "../../components/hiv_page_content/PageContent";
import { userIdActionType, userIdActionTypeEnums } from '../../actions/userIdActions';
import Template from "../../components/hiv_template/Template";
import { recordEvent } from "../../utils";
import './Outro.scss';

type userIdInterface = string
type getNewUserIdType = () => void;

interface OutroInterface { 
  updateUserId: (userId: string) => object,
  lexicon: Lexicon
}

const Outro: React.FC<OutroInterface> = ({ updateUserId, lexicon }) => {
  const getNewUserId: getNewUserIdType = () => {
    fetch('/api/userId', { method: 'POST' })
      .then(res => res.json())
      .then(json => {
        updateUserId(JSON.stringify(json.user_id));
        sessionStorage.setItem('userId', JSON.stringify(json.user_id));
      })
      .catch(err => { console.error(err) })
  }

  const saveUserLog = () => {
    fetch('/api/events',  { method: 'PUT',
                            body: JSON.stringify({ 'events': sessionStorage, 'userId': sessionStorage.getItem('userId') }),
                            headers: { 'Content-Type': 'application/json' }
                          })
      .then(res => res.json())
      .catch(err => { console.error(err) })
  }

  return (
    <Template
      page="outro"
    >
      <PageContent
        title={lexicon.get('title')}
        body={lexicon.get('body')}
      />
      <div className="outro-nav-group">
        <Link className="dialog-box PrEP" to="/start" onClick={() => { recordEvent("RESTART"); saveUserLog(); }} >
          {lexicon.get('introduction')}
        </Link>
        <Link className="dialog-box PrEP" to="/menu" onClick={() => { recordEvent("RETURN_TO_MENU"); saveUserLog(); }} >
          {lexicon.get('main_menu')}
        </Link>
        <Link className="dialog-box dark-blue" to="/" onClick={() => { recordEvent("DONE"); saveUserLog(); getNewUserId(); } } >
          {lexicon.get('all_done')}
        </Link>
      </div>
    </Template>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<userIdActionType>) => ({
  updateUserId: (id: string) => dispatch({ type: userIdActionTypeEnums.updateUserId, payload: id }),
});

const connectedOutro = connect(null, mapDispatchToProps)(Outro);

const OutroLexicon = new Lexicon(texts, 'en', 'client/src/pages/outro/OutroStrings.json');

const EditableNote = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={OutroLexicon}
    component={connectedOutro}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableNote;
