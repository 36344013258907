import React from 'react';
import './IconBulletList.scss';
import Markdown from '../Markdown';
import { BulletListInterface } from './util';

const IconBulletList: React.FC<BulletListInterface> = ({ items, bulletImages = [] }) => (
  <ul className="icon-bullet-list">
    { 
      items.map((item: string, i: number) => (
        <li key={item}>
          { bulletImages[i] && <img src={bulletImages[i]} alt="" aria-hidden /> }
          <Markdown text={item} />
        </li>
      ))
    }
  </ul>
);


export default IconBulletList;
