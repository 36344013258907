import React, { useState } from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import texts from "./UseItStrings.json";
import Template from "../../components/hiv_template/Template";
import NclTabs from "../../components/ncl/NclTabs/Tabs";
import Markdown from "../../components/ncl/Markdown";
import { TabletTab, MobileTab } from '../../components/hiv_tab/Tab';
import IconBulletList from "../../components/ncl/NclBulletList/IconBulletList";
import DialogWithOverlay from "../../components/ncl/NclDialog/DialogWithOverlay";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import { arrayFromLexicon, recordEvent } from "../../utils";
import { hivMethodNames } from '../../components/hiv_tab/Tab';
import './UseIt.scss';

const bulletImages = require.context('./images', true);

interface UseItInterface { lexicon: Lexicon }
interface TabInterface { 
  itemKey: string,
  items: string[],
};

type itemWithDialog = 'PEP' | 'PrEP';

const hivMethods = ['PrEP', 'PEP', 'condoms', 'hivTesting', 'treatment'];

const bulletImagesHash: { [index: string]: string[] } = ({
  PrEP: ['prep-blue', 'alarm-blue', 'cross-blue', 'shield-blue', 'key-blue'].map(str => bulletImages(`./${str}.svg`)),
  PEP: ['pep-light-blue', 'calendar-light-blue', 'cross-light-blue', 'shield-light-blue', 'key-light-blue'].map(str => bulletImages(`./${str}.svg`)),
  condoms: ['condoms-green', 'male-condom-green', 'female-condom-green', 'lube-green', 'pep-green'].map(str => bulletImages(`./${str}.svg`)),
  hivTesting: ['hiv-testing-light-orange', 'cross-light-orange'].map(str => bulletImages(`./${str}.svg`)),
  treatment: ['treatment-orange', 'cross-orange', 'cross-orange'].map(str => bulletImages(`./${str}.svg`))
});

const ContentComponent: React.FC<TabInterface> = ({ itemKey, children, items }) => (
  <>
    <div className={`selected-content ${itemKey}`}>
      <IconBulletList
        items={items}
        bulletImages={bulletImagesHash[itemKey]}
      />
      { children }
    </div>
  </>
);

const UseIt: React.FC<UseItInterface> = ({ lexicon }) => {
  const [selectedItem, setselectedItem] = useState<string | null>(null);
  const [isDialogOn, setIsDialogOn] = useState<boolean>(false);

  const dialogToggle = (): void => {
    if (isDialogOn === true) setIsDialogOn(false);
    else setIsDialogOn(true);
  }

  const iconToggle = (icon: string): void => {
    recordEvent(`TAB-${icon}`);
    if (selectedItem === icon) setselectedItem(null);
    else setselectedItem(icon);
  }

  const dialogLexicon = (lexicon.subset('dialog') as Lexicon);

  const titleBarText = selectedItem ? hivMethodNames[selectedItem] : lexicon.get('select_one');
  const isDialogToggleNeeded = selectedItem === 'PrEP' || selectedItem === 'PEP';

  return (
    <>
      <Template
        page="useIt"
        ariaHidden={isDialogOn}
        isShowingNotesButton
      >
        <h1>{lexicon.get('title')}</h1>
        <Markdown text={lexicon.get('body') || ''} />
        <NclTabs 
          itemKeys={hivMethods}
          TabletTabComponent={TabletTab}
          MobileTabComponent={MobileTab}
          selectedItem={selectedItem}
          clickHandler={iconToggle}
          ContentComponent={({ itemKey }): JSX.Element => (
            <ContentComponent itemKey={itemKey} items={arrayFromLexicon(lexicon, `methodInfo.${itemKey}`)}>
              { 
                isDialogToggleNeeded && (
                  <button
                    className={`dialog-box ${selectedItem}`}
                    onClick={dialogToggle}
                  >
                    { dialogLexicon.get(`${(selectedItem as itemWithDialog)}.header`) }
                  </button>
                )
              }
            </ContentComponent>
          )}
          isShowingTitleBar
          titleBarText={titleBarText}
        />
      </Template>

      {
        isDialogToggleNeeded && (
          <DialogWithOverlay
            clickHandler={dialogToggle}
            isModalOn={isDialogOn}
            extraClass={(selectedItem as itemWithDialog)}
          >
            <h1 className={`dialog-box ${selectedItem}`}>{dialogLexicon.get(`${(selectedItem as itemWithDialog)}.header`)}</h1>
            <div className="dialog-body">
              <p>{dialogLexicon.get(`${(selectedItem as itemWithDialog)}.body`)}</p>
              { selectedItem && <BulletList items={arrayFromLexicon(dialogLexicon, `${selectedItem}.list`)} />}
            </div>
          </DialogWithOverlay>
        )
      }
    </>
  );
};

const UseItLexicon = new Lexicon(texts, 'en', 'client/src/pages/useIt/UseItStrings.json');

const EditableUseIt = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={UseItLexicon}
    component={UseIt}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableUseIt;
