import { noteActionTypeEnums, noteActionType } from '../actions/noteActions';

const noteReducer = (state = '', action: noteActionType) => {
  switch (action.type){
    case noteActionTypeEnums.updateNote: {
      return action.payload
    }

    default:
      return state;
  }
};

export default noteReducer;
