import React from 'react';
import './ImageBanner.scss';

type color = 'orange' | 'blue' | 'green';
export type ColorsType = color[];

interface ImageBannerInterface {
  imageSrc: any,
  method: string,
  text1: string,
  text2: string,
  colors: ColorsType
}


const ImageBanner:React.FC<ImageBannerInterface> = ({ imageSrc, text1, text2, colors, method }) => (
  <div className="ImageBanner">
    <img src={imageSrc} alt={`${text1} ${text2}`} className={method} />
    <div className="text-container">
      <span className={colors[0]}>{text1}</span>
      { ' ' }
      <span className={colors[1]}>{text2}</span>
    </div>
  </div>
)

export default ImageBanner;
