import React from 'react';
import { connect } from 'react-redux';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { rootReducerState } from '../../reducers';
import { arrayFromLexicon } from "../../utils";
import texts from './TalkToProviderStrings.json';
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import Markdown from '../../components/ncl/Markdown';
import ProviderHeaderImage from './provider-header-image.jpg';
import './TalkToProvider.scss';

interface TalkToProviderInterface { 
  notes: string,
  lexicon: Lexicon
}

const TalkToProvider: React.FC<TalkToProviderInterface> = ({ notes, lexicon }) =>  {
  const bodyList = arrayFromLexicon(lexicon, 'list');

  return (
    <Template
      page="talk-to-provider"
      headerImage={ProviderHeaderImage}
      isShowingNotesButton
    >
      <PageContent
        title={lexicon.get('title')}
        body={lexicon.get('body1')}
      />
      <BulletList
        items={bodyList}
      />
      {
        notes && (
          <>
            <Markdown text={(lexicon.get('body2') as string)} />
            <div id="view-only-notes">
              {notes}
            </div>
          </>
        )
      }
    </Template>
  );
}

const mapStateToProps = ({ notes }: rootReducerState) => ({ notes });

const connectedTalkToProvider = connect(mapStateToProps)(TalkToProvider);

const TalkToProviderLexicon = new Lexicon(texts, 'en', 'client/src/pages/talkToProvider/TalkToProviderStrings.json');

const EditableTalkToProvider = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={TalkToProviderLexicon}
    component={connectedTalkToProvider}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableTalkToProvider;
