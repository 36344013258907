import React from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { arrayFromLexicon } from "../../utils";
import texts from './STDstrings.json';
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import Markdown from "../../components/ncl/Markdown";
import './STD.scss';

import stdHeaderImage from './std-header-image.jpg';

interface STDInterface { lexicon: Lexicon }

const STD: React.FC<STDInterface> = ({ lexicon }) => {
  const bodyList = arrayFromLexicon(lexicon, 'list');

  return (
    <Template
      page="std"
      headerImage={stdHeaderImage}
      isShowingNotesButton
    >
      <PageContent
        title={lexicon.get('title')}
        body={lexicon.get('body1')}
      />
      <BulletList items={bodyList} />
      <Markdown text={lexicon.get('body2') || ''} />
    </Template>
  )
};

const STDlexicon = new Lexicon(texts, 'en', 'client/src/pages/std/STDstrings.json');

const EditableSTD = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={STDlexicon}
    component={STD}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableSTD;
