import React from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import texts from './OptionsStrings.json';
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import IconBulletList from "../../components/ncl/NclBulletList/IconBulletList";
import { arrayFromLexicon } from "../../utils";
import './Options.scss';

import PrEPimage from '../../shared_images/colored_icons/PrEP-OFF.svg';
import PEPimage from '../../shared_images/colored_icons/PEP-OFF.svg';
import CondomsImage from '../../shared_images/colored_icons/condoms-OFF.svg';
import hivTestingImage from '../../shared_images/colored_icons/hivTesting-OFF.svg';
import TreatmentImage from '../../shared_images/colored_icons/treatment-OFF.svg';

const iconImages = [
  PrEPimage,
  PEPimage,
  CondomsImage,
  hivTestingImage,
  TreatmentImage,
];

interface OptionsInterface { lexicon: Lexicon }

const Options: React.FC<OptionsInterface> = ({ lexicon }) => {
  const listMarkdowns = arrayFromLexicon(lexicon, 'list')

  return (
    <Template page="options">
      <PageContent
        title={lexicon.get('title')}
        body={lexicon.get('body')}
      />
      <IconBulletList
        items={listMarkdowns}
        bulletImages={iconImages}
      />
    </Template>
  );
};

const OptionsLexicon = new Lexicon(texts, 'en', 'client/src/pages/options/OptionsStrings.json');

const EditableOptions = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={OptionsLexicon}
    component={Options}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableOptions;
