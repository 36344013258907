import React from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { arrayFromLexicon } from "../../utils";
import texts from './ProtectStrings.json';
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import IconBulletList from "../../components/ncl/NclBulletList/IconBulletList";
import './Protect.scss'

import condomsImage from '../../shared_images/colored_icons/condoms-OFF.svg';
import hivTestingImage from '../../shared_images/colored_icons/hivTesting-OFF.svg';
import treatmentImage from '../../shared_images/colored_icons/treatment-OFF.svg';
import PrEPImage from '../../shared_images/colored_icons/PrEP-OFF.svg';
import PEPImage from '../../shared_images/colored_icons/PEP-OFF.svg';
import ProtectHeaderImage from './protected-header-image.jpg';

const iconImages = [
  PrEPImage,
  PEPImage,
  condomsImage,
  hivTestingImage,
  treatmentImage,
];

interface ProtectInterface { lexicon: Lexicon }

const Protect: React.FC<ProtectInterface> = ({ lexicon }) => {
  const bodyList = arrayFromLexicon(lexicon, 'list');

  return (
    <Template
      page="protect"
      headerImage={ProtectHeaderImage}
      isShowingNotesButton
    >
      <PageContent
        title={lexicon.get('title')}
        body=""
      />
      <IconBulletList
        items={bodyList}
        bulletImages={iconImages}
      />
    </Template>
  );
};

const ProtectLexicon = new Lexicon(texts, 'en', 'client/src/pages/protect/ProtectStrings.json');

const EditableProtect = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={ProtectLexicon}
    component={Protect}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableProtect;
