import React from "react";
import './Tabs.scss'

interface TabsInterface {
  itemKeys: string[],
  TabletTabComponent: React.FC<{ itemKey: string }>,
  MobileTabComponent: React.FC<{ itemKey: string }>,
  ContentComponent: React.FC<{ itemKey: string }>,
  selectedItem: string | null,
  clickHandler: (icon: string) => void,
  isShowingTitleBar?: boolean,
  titleBarText?: string
}

const NclTabs: React.FC<TabsInterface> = ({
  itemKeys,
  selectedItem,
  clickHandler,
  TabletTabComponent,
  MobileTabComponent,
  ContentComponent,
  titleBarText ,
  isShowingTitleBar = false
}) => {
  return (
    <>
      <div className="tabs tablet">
        <div className="tabs-icon-bar">
          {
            itemKeys.map((iconKey: string) => {
              const extraClass = selectedItem === iconKey ? 'selected' : '';

              return (
                <button
                  className={`tab-icon-container ${iconKey} ${extraClass}`}
                  key={iconKey}
                  onClick={(): void => { clickHandler(iconKey); }}
                >
                  <TabletTabComponent itemKey={iconKey} />
                </button>
              );
            })
          }
        </div>
        { isShowingTitleBar && <div className="title-bar">{titleBarText}</div> }
        
        { selectedItem && <ContentComponent itemKey={selectedItem} />}
      </div>

      <div className="tabs mobile">
        <div className="tabs-icon-bar">
          {
            itemKeys.map((iconKey: string) => {
              const extraButtonClass = selectedItem === iconKey ? 'selected' : '';
              const extraContentClass = selectedItem === iconKey ? 'slide-down' : 'slide-up'

              // this is purposely made not a button because we don't want screen reader users to interact with it
              return (
                <div className={`tab-mobile-grouping ${iconKey}`} key={iconKey}>
                  <button
                    className={`tab-icon-container ${extraButtonClass}`}
                    onClick={(): void => { clickHandler(iconKey); }}
                  >
                    <MobileTabComponent itemKey={iconKey} />
                  </button>
                  <div className={`selected-content-mobile-grouping ${extraContentClass}`}>
                    <ContentComponent itemKey={iconKey} />
                    <div className="accordion-bottom" />
                  </div>
                </div>
              );
            })
          }
        </div>
      </div>
    </>
  );
}

export default NclTabs;
