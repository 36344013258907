import { unlockProviderActionType, unlockProviderActionTypeEnums } from '../actions/unlockProviderActions';
import { linkKeys } from '../pages/menu/Menu';

const initialState = {
  [linkKeys.prevent]: false,
  [linkKeys.useIt]: false,
  [linkKeys.sideEffects]: false,
  [linkKeys.getIt]: false,
}

const unlockProviderReducer = (state = initialState, action: unlockProviderActionType) => {
  switch (action.type){
    case unlockProviderActionTypeEnums.visitMenuLink: {
      return { ...state, [action.payload]: true }
    }

    default:
      return state;
  }
};

export default unlockProviderReducer;
