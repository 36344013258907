import { surveyActionTypeEnums, surveyActionType } from '../actions/surveyActions';

export enum surveyKeys {
  experience = 'experience',
  helpfulness = 'helpfulness',
  recommend = 'recommend',
  revisit = 'revisit'
}

const initialState = {
  [surveyKeys.experience]: [],
  [surveyKeys.helpfulness]: [],
  [surveyKeys.recommend]: [],
  [surveyKeys.revisit]: []
}

const surveyReducer = (state = initialState, action: surveyActionType) => {
  switch (action.type){
    case surveyActionTypeEnums.answerSurvey: {
      return { ...state, [action.payload.question]: [action.payload.answer] }
    }

    default:
      return state;
  }
};

export default surveyReducer;
