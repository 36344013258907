import React from "react";
import { connect } from 'react-redux';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { Link } from 'react-router-dom';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { rootReducerState } from '../../reducers';
import Template from "../../components/hiv_template/Template";
import UcsfFooter from "./UcsfFooter";
import { clearEvents, recordEvent } from "../../utils";
import texts from './WelcomeStrings.json';
import './Welcome.scss';

interface WelcomeInterface { 
  userId: string,
  lexicon: Lexicon,
}

const Welcome: React.FC<WelcomeInterface> = ({ userId, lexicon }) => {
  clearEvents();

  return (
    <>
      <Template page="welcome">
        <div className="welcome-content">
          <h1>{lexicon.get('title')}</h1>
          <div className="welcome-body">{lexicon.get('body')}</div>
          <Link to="/start" className="dialog-box PrEP" onClick={ () => {recordEvent("START");} }>
            {lexicon.get('button')}
          </Link>
        </div>
      </Template>
      <UcsfFooter userId={userId} />
    </>
  );
};

const mapStateToProps = ({ userId }: rootReducerState) => ({ userId });

const connectedWelcome = connect(mapStateToProps)(Welcome);

const WelcomeLexicon = new Lexicon(texts, 'en', 'client/src/pages/welcome/WelcomeStrings.json');

const EditableWelcome = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={WelcomeLexicon}
    component={connectedWelcome}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableWelcome;
