import React from 'react';
import Markdown from '../ncl/Markdown';
import './ImageKanban.scss';

interface ImageKanbanInterface { 
  imageName: string,
  headerText: string,
  appendixMarkdown: string,
  imageSrc: any
}

const ImageKanban:React.FC<ImageKanbanInterface> = ({ headerText, imageName, appendixMarkdown, imageSrc }) => (
  <div className="ImageKanban">
    <header>
      <h1>{headerText}</h1>
    </header>
    <img className={`image ${imageName}`} src={imageSrc} alt={headerText} />
    <Markdown text={appendixMarkdown} />
  </div>
)

export default ImageKanban;
