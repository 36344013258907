import React from 'react';
import { connect } from 'react-redux';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import { Dispatch } from "redux";
import { noteActionTypeEnums, noteActionType } from '../../actions/noteActions';
import { rootReducerState } from '../../reducers';
import NclNote from '../ncl/NclNote/NclNote';
import texts from './NoteStrings.json';
import './Note.scss';

interface NoteInterface {
  notes: string,
  saveGlobalNotes: (notes: string) => object,
  lexicon: Lexicon
}

const Note: React.FC<NoteInterface> = ({ notes, saveGlobalNotes, lexicon }) => (
  <NclNote
    globalNotes={notes}
    saveGlobalNotes={saveGlobalNotes}
    headerText={lexicon.get('headerText') as string}
    mobileText={lexicon.get('mobileText') as string}
    tabletText={lexicon.get('tabletText') as string}
    placeholderText={lexicon.get('placeholderText') as string}
    cancelText={lexicon.get('cancelText') as string}
    saveText={lexicon.get('saveText') as string}
  />
)

const mapStateToProps = ({ notes }: rootReducerState) => ({ notes });
const mapDispatchToProps = (dispatch: Dispatch<noteActionType>) => ({
  saveGlobalNotes: (notes: string) => dispatch({ type: noteActionTypeEnums.updateNote, payload: notes }),
});

const connecteNote = connect(mapStateToProps, mapDispatchToProps)(Note);

const NoteLexicon = new Lexicon(texts, 'en', 'client/src/components/hiv_note/NoteStrings.json');

const EditableNote = () => (
  <EditWrapper
    lexicon={NoteLexicon}
    component={connecteNote}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableNote;

