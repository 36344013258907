import { action, ActionType  } from "typesafe-actions";

// use typescript enum rather than action constants
export enum surveyActionTypeEnums {
  answerSurvey = "ANSWER SURVEY",
}

const surveyActions = {
  answerSurvey: (question: string, answer: string) => action(surveyActionTypeEnums.answerSurvey, { question, answer }),
};

export type surveyActionType = ActionType<typeof surveyActions>

export default surveyActions;
