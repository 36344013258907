import React from "react";
import { Link } from "react-router-dom";
import "./NclNav.scss";

export interface NavItem {
  link: string;
  label: string | null;
  visited?: boolean;
  clickHandler?: () => void;
}

export const NclNav: React.FC<{itemComponent?: React.FC<NavItem>, items: NavItem[]}> = ({ itemComponent, items }) => {
  const ItemComponent = itemComponent || NavItemComponent;
  return (
    <ul className="nav">
      { items.map( (item, idx) =>
        <Link to={item.link} key={idx} onClick={item.clickHandler}>
          <ItemComponent {...item} />
        </Link>,
      ) }
    </ul>
  );
};

const NavItemComponent: React.FC<NavItem> = ({ label, visited }) => (
  <li className={`nav-item ${visited ? "visited" : ""}`}>
    <span className="nav-label">{label}</span>
  </li>
);