import React from "react";
import Markdown from "../Markdown";

interface PlainPageContentInterface {
  title: string | null,
  body?: string | null,
  
}

const PlainPageContent: React.FC<PlainPageContentInterface> = ({ title, body = '' }) => (
  <>
    { title && <h1>{title}</h1> }
    { body && <Markdown text={body} />}
  </>
);

export default PlainPageContent;
