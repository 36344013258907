import React, { useState } from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { arrayFromLexicon } from "../../utils";
import texts from './WhyStrings.json';
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import DialogWithOverlay from "../../components/ncl/NclDialog/DialogWithOverlay";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import WhyHeaderImage from './why-header-image.jpg';
import './Why.scss';

interface WhyInterface { lexicon: Lexicon }

const Why: React.FC<WhyInterface> = ({ lexicon }) => {
  const [isDialogOn, setIsDialogOn] = useState<boolean>(false);

  const dialogToggle = (): void => {
    if (isDialogOn === true) setIsDialogOn(false);
    else setIsDialogOn(true);
  }

  const bodyList = arrayFromLexicon(lexicon, 'list');
  const dialogList = arrayFromLexicon(lexicon, 'dialog.list')

  return (
    <>
      <Template
        page="why"
        ariaHidden={isDialogOn}
        headerImage={WhyHeaderImage}
      >
        <PageContent
          title={lexicon.get('title')}
          body={lexicon.get('body')}
        />
        <BulletList items={bodyList} />
        <button
          className="dialog-box PrEP"
          onClick={dialogToggle}
          dangerouslySetInnerHTML={{ __html: (lexicon.get('dialog.title') as string) }}
        />
      </Template>

      {
        isDialogOn && (
          <DialogWithOverlay
            clickHandler={dialogToggle}
            isModalOn={isDialogOn}
            extraClass="why-dialog"
          >
            <h1
              className="dialog-box PrEP"
              tabIndex={-1}
              dangerouslySetInnerHTML={{ __html: (lexicon.get('dialog.title') as string) }}
            />
            <div className="dialog-body PrEP">
              <BulletList items={dialogList} />
            </div>
          </DialogWithOverlay>
        )
      }
    </>
  );
};

const WhyLexicon = new Lexicon(texts, 'en', 'client/src/pages/why/WhyStrings.json');

const EditableWhy = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={WhyLexicon}
    component={Why}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableWhy;
