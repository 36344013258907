import { action, ActionType  } from "typesafe-actions";

// use typescript enum rather than action constants
export enum unlockProviderActionTypeEnums {
  visitMenuLink = "VISIT MENU LINK",
}

const unlockProviderActions = {
  visitMenuLink: (link: string) => action(unlockProviderActionTypeEnums.visitMenuLink, link),
};

export type unlockProviderActionType = ActionType<typeof unlockProviderActions>

export default unlockProviderActions;
