import React from "react";
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import texts from './GetItStrings.json';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { arrayFromLexicon  } from "../../utils";
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import IconBulletList from "../../components/ncl/NclBulletList/IconBulletList";
import './GetIt.scss';

import PrEPimage from '../../shared_images/colored_icons/PrEP-OFF.svg';
import PEPimage from '../../shared_images/colored_icons/PEP-OFF.svg';
import CondomsImage from '../../shared_images/colored_icons/condoms-OFF.svg';
import hivTestingImage from '../../shared_images/colored_icons/hivTesting-OFF.svg';
import TreatmentImage from '../../shared_images/colored_icons/treatment-OFF.svg';

interface GetItInterface { lexicon: Lexicon }

const iconImages = [
  PrEPimage,
  PEPimage,
  CondomsImage,
  hivTestingImage,
  TreatmentImage,
];

const GetIt: React.FC<GetItInterface> = ({ lexicon }) => {
  const listMarkdowns = arrayFromLexicon(lexicon, 'list');

  return (
    <Template page="get-it" isShowingNotesButton>
      <PageContent title={lexicon.get('title')} />
      <IconBulletList
        items={listMarkdowns}
        bulletImages={iconImages}
      />
    </Template>
  );
};

const UseItLexicon = new Lexicon(texts, 'en', 'client/src/pages/getIt/GetItStrings.json');

const EditableGetIt = () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={UseItLexicon}
    component={GetIt}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditableGetIt;
