import React, { useState } from 'react';
import { Lexicon, EditWrapper } from '@nitidbit/lexicon';
import LogoutButton from '../../components/hiv_logout_button/LogoutButton';
import { arrayFromLexicon } from "../../utils";
import texts from './PregnancyStrings.json';
import Template from "../../components/hiv_template/Template";
import PageContent from "../../components/hiv_page_content/PageContent";
import DialogWithOverlay from "../../components/ncl/NclDialog/DialogWithOverlay";
import BulletList from "../../components/ncl/NclBulletList/BulletList";
import PregnancyHeaderImage from './pregnancy-header-image.jpg';
import './Pregnancy.scss';

interface PregnancyInterface { lexicon: Lexicon }

const Pregnancy: React.FC<PregnancyInterface> = ({ lexicon }) => {
  const [isDialogOn, setIsDialogOn] = useState<boolean>(false);

  const dialogToggle = (): void => {
    if (isDialogOn === true) setIsDialogOn(false);
    else setIsDialogOn(true);
  }

  const dialogList = arrayFromLexicon(lexicon, 'dialog.list');

  return (
    <>
      <Template
        page="pregnancy"
        ariaHidden={isDialogOn}
        headerImage={PregnancyHeaderImage}
        isShowingNotesButton
      >
        <PageContent
          title={lexicon.get('title')}
          body={lexicon.get('body')}
        />
        <button
          className="dialog-box PrEP"
          onClick={dialogToggle}
        >
          {lexicon.get('dialog.header')}
        </button>
      </Template>

      {
        isDialogOn && (
          <DialogWithOverlay
            clickHandler={dialogToggle}
            isModalOn={isDialogOn}
            extraClass="pregnancy-dialog"
          >
            <h1 className="dialog-box PrEP" tabIndex={-1}>{lexicon.get('dialog.header')}</h1>
            <div className="dialog-body">
              <BulletList items={dialogList} />
            </div>
          </DialogWithOverlay>
        )
      }
    </>
  );
};

const PregnancyLexicon = new Lexicon(texts, 'en', 'client/src/pages/pregnancy/PregnancyStrings.json');

const EditablePregnancy= () => (
  <EditWrapper
    OptionalLogoutButton={LogoutButton}
    lexicon={PregnancyLexicon}
    component={Pregnancy}
    apiUpdateUrl={process.env.REACT_APP_LEXICON_UPDATE_URL as string}
  />
);

export default EditablePregnancy;
